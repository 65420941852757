<template>
  <div>
    <the-header title="Apaluma Apollo 11"></the-header>
    <main>
      <router-view :avalableAPIs="storeAPIs"></router-view>
    </main>
    <the-footer title="Apaluma"></the-footer>
  </div>
</template>

<script>
export default {
  computed: {
    storeAPIs() {
      return this.$store.getters["apis/apis"];
    },
  },
};
</script>

<style>
html {
  font-family: sans-serif;
}

body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: [header] auto [main] 1fr [footer] auto;

  /* Optional */
  margin: 0;
  line-height: 1.6;
}

main {
  grid-row: content;

  /* Optional */
  padding: 1rem;
  background-color: #ffffff;
  color: #333;
}
</style>