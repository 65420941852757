<template>
  <section id="flex-body">
    <base-card>
      <H1>{{ title }}</H1>
      <p>{{ description }}</p>
      <base-button v-if="!showDetails" @click="toggleDetails">
        Show Details
      </base-button>
      <section v-if="showDetails">
        <section v-if="parameters.length > 0">
          <form @submit.prevent="submitForm">
            <div
              v-for="(item, index) in parameterList"
              :key="`item.id-${index}`"
              class="form-control"
            >
              <label for="item">{{ item.label }}</label>
              <input
                id="item.value"
                name="item.value"
                type="text"
                v-model.trim="item.inputValue"
              />
            </div>
            <div>
              <base-button> Fetch Info </base-button>
              <p v-if="isLoading">Please wait, loading data...</p>
            </div>
          </form>
        </section>
        <section v-else>
          <p>No input needed.</p>
          <p v-if="isLoading">Please wait, loading data...</p>
          <base-button @click="submitForm"> Fetch Info </base-button>
        </section>
        <section v-if="results.length > 0">
          <base-table :data="results"></base-table>
        </section>
        <!-- <section v-else>
          <p>No data available to display.</p>
        </section> -->
        <br />
        <base-button @click="toggleDetails" mode="flat">
          Hide Details
        </base-button>
      </section>
    </base-card>
  </section>
</template>

<script>
import BaseTable from "./UI/BaseTable.vue";
export default {
  components: { BaseTable },
  props: [
    "title",
    "description",
    "baseUrl",
    "endPoint",
    "parameters",
    "responseType",
  ],
  data() {
    return {
      isLoading: null,
      parameterList: [],
      showDetails: false,
      results: [],
      error: null,
    };
  },
  // computed: {
  //   activeOption() {
  //     return this.modelValue;
  //   }
  // },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    submitForm() {
      console.log(this.parameterList);

      const ApiKey = this.$store.getters["apis/apiKey"];
      const baseUrl = this.baseUrl;
      const endPoint = this.endPoint;

      //let apiUrl = `${baseUrl}${endPoint}?apikey=${ApiKey}&first_row=${firstRow}&number_of_rows=${numberOfRows}`;
      let apiUrl = `${baseUrl}${endPoint}?apikey=${ApiKey}`;

      for (const id in this.parameterList) {
        apiUrl += `&${this.parameterList[id].value}=${this.parameterList[id].inputValue}`;
      }

      console.log(apiUrl);

      this.isLoading = true;
      this.error = null;
      fetch(apiUrl)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((response) => {
          this.isLoading = false;
          const results = [];
          console.log(response);
          console.log(typeof response);
          console.log(this.responseType);

          let data = null;
          if (this.responseType === "0") {
            data = response.results;
          } else if (this.responseType === "1") {
            data = response.result.out_cur;
          } else if (this.responseType === "2") {
            data = [response];
          } else if (this.responseType === "3") {
            data = response.result.rc_ret_cur;
          } else if (this.responseType === "4") {
            data = response.result.ret_cur;
          } else if (this.responseType === "5") {
            data = response;
          }

          //console.log(typeof data.results)
          //console.log(data.results[0])
          //console.log(data.results[1])
          //console.log(Object.keys(data.results[0]));
          for (const id in data) {
            let dataRow = {};
            dataRow["id"] = id;
            for (let key in data[id]) {
              dataRow[key] = data[id][key];
            }
            results.push(dataRow);
            // results.push({
            //   id: id,
            //   AI_ID: data.results[id].AI_ID,
            //   COUNTY: data.results[id].COUNTY,
            //   FACILITY_CLASSIFICATION: data.results[id].FACILITY_CLASSIFICATION,
            //   FACILITY_STATUS: data.results[id].FACILITY_STATUS,
            // });
          }
          console.log(results);
          this.results = results;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.error = "Failed to fetch data - please try again later.";
        });
      this.sendAnalytics();
    },
    sendAnalytics() {
      const token = this.$store.getters["token"];

      let monitorUrl =
        "https://apaluma-apollo11-default-rtdb.firebaseio.com/monitor.json?auth=" +
        token;

      fetch(monitorUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: this.$store.getters["userId"],
          datetime: new Date().toISOString(),
          api_url: this.endPoint,
        }),
      })
        .then((response) => {
          if (response.ok) {
            // ...
          } else {
            throw new Error("Could not save data!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.parameterList = this.parameters;
    //console.log(this.parameterList)
  },
};
</script>

<style scoped>
#flex-body {
  flex-grow: 1;
}

form {
  margin: 2rem auto;
  max-width: 40rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2rem;
  background-color: #ffffff;
}

.form-control {
  margin: 0.5rem 0;
}

.form-control.invalid input {
  border-color: red;
}

.form-control.invalid label {
  color: red;
}

label {
  font-weight: bold;
}

h2 {
  font-size: 1rem;
  margin: 0.5rem 0;
}

input,
select {
  display: block;
  width: 100%;
  font: inherit;
  margin-top: 0.5rem;
}

select {
  width: auto;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  width: auto;
  margin-right: 1rem;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  font-weight: normal;
}

button {
  font: inherit;
  border: 1px solid #070024;
  background-color: #070024;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}
</style>