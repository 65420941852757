<template>
  <section>
    <base-card>
      <template v-slot:title>API Categories</template>
      <nav>
        <ul>
          <li>
            <base-button @click="selectApis('water')" :mode="(selectedCategory === 'water')?'selected':'button'">Water</base-button>
          </li>
          <li>
            <base-button @click="selectApis('air')" :mode="(selectedCategory === 'air')?'selected':'button'">Air</base-button>
          </li>
          <li>
            <base-button @click="selectApis('waste')" :mode="(selectedCategory === 'waste')?'selected':'button'" >Waste</base-button>
          </li>
          <li>
            <base-button @click="selectApis('service')" :mode="(selectedCategory === 'service')?'selected':'button'">Service</base-button>
          </li>
        </ul>
      </nav>
    </base-card>
    <api-input-output
      v-for="api in selectedAPIs"
      :key="api.id"
      :title="api.title"
      :description="api.description"
      :baseUrl="api.baseUrl"
      :endPoint="api.endPoint"
      :parameters="api.parameters"
      :responseType="api.responseType"
    >
    </api-input-output>
  </section>
</template>

<script>
import ApiInputOutput from "../components/ApiInputOutput";

export default {
  components: {
    "api-input-output": ApiInputOutput,
  },
  props: ["avalableAPIs"],
  data() {
    return {
      selectedCategory: "air",
      fullAPIs: null,
      selectedAPIs: null,
    };
  },
  methods: {
    selectApis(category) {
      this.selectedCategory = category;
      let selected = [];
      this.fullAPIs.forEach((api) => {
        if (api.category === category) {
          selected.push(api);
        }
      });
      // console.log("TNU ======= " + selected + " === " + category);
      this.selectedAPIs = selected;
    },
  },
  created() {
    this.fullAPIs = this.avalableAPIs;
    this.selectApis(this.selectedCategory);
  },
};
</script>

<style scoped>
h1 {
  font-size: 30pt;
  color: rgb(54, 0, 61);
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 20pt;
  justify-content: center;
  align-items: center;
}

li {
  margin: 0 0.5rem;
}

button {
  font: inherit;
  border: 1px solid #070024;
  background-color: #070024;
  color: white;
  cursor: pointer;
  padding: 0.75rem 2rem;
  border-radius: 30px;
}

button:hover,
button:active {
  border-color: #002350;
  background-color: #002350;
}
</style>
