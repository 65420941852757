<template>
  <div class="table-container" v-if="processedData.length">
    <table class="styled-table">
      <thead>
        <tr>
          <th v-for="(column, index) in columns" :key="index">
            {{ column }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in processedData" :key="rowIndex">
          <td v-for="(column, colIndex) in columns" :key="colIndex">
            {{ row[column] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <p>No data available to display.</p>
  </div>
</template>

<script>
export default {
  name: "NiceTable",
  props: {
    // The data prop should be an array of objects
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    // Extracts the columns based on the keys of the first object in the data array
    columns() {
      return this.data.length > 0 ? Object.keys(this.data[0]) : [];
    },
    // Processes the data to make it compatible with the table display
    processedData() {
      // let processedData = [];
      // for (let item in this.data) {
      //   console.log("TNU: ===== " + item);
      //   delete item.id;
      //   processedData.push(item);
      // }
      // console.log(this.data);
      // console.log(processedData);
      // return processedData;
      return this.data;
    },
  },
};
</script>

<style scoped>
.table-container {
  width: 100%; /* Adjust the width as needed */
  max-height: 300px; /* Set the height as needed */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: auto; /* Enable horizontal scroll */
  border: 1px solid #ddd; /* Optional: adds a border to the container */
  margin-top: 20px;
}

table {
  width: 100%; /* Table should take full width of the container */
  border-collapse: collapse; /* Collapses the borders into a single border */
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 12px;
  font-family: "Arial", sans-serif;
  text-align: left;
}

.styled-table thead tr {
  background-color: #470058;
  color: white;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
  border: 1px solid #dddddd;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #27002b;
}

.styled-table tbody tr:hover {
  background-color: #f1f1f1;
}
</style>
