<template>
  <button :class="mode">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode']
}
</script>

<style scoped>
button {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #4e0148;
  border: 1px solid #4e0148;
  color: white;
  cursor: pointer;
}

button:hover,
button:active {
  background-color: #94157f;
  border-color: #270041;
}

.selected {
  padding: 0.75rem 1.5rem;
  font-family: inherit;
  background-color: #f391e3;
  border: 1px solid #f391e3;
  color: #4e0148;
  cursor: pointer;
}

.selected:hover,
.selected:active {
  background-color: #f391e3;
  border-color: #270041;
}

.flat {
  background-color: transparent;
  color: #3a0061;
  border: none;
}

.flat:hover,
.flat:active {
  background-color: #edd2ff;
}
</style>